/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */

import { Box, FormControl, FormLabel, HStack, Switch, VStack } from '@chakra-ui/react';
import { ColumnFiltersState, createColumnHelper, Row } from '@tanstack/react-table';
import React from 'react';
import Select from 'react-select';
import { useImmerReducer } from 'use-immer';

import AvailablePreviewList from '@/components/available-preview-list/AvailablePreviewList';
import FilterSelect, { FilterSelectValue } from '@/components/filter-select/FilterSelect';
import {
  ALPHANUMERIC_TABLE_SORT,
  DEFAULT_ADD_ALL_TO_VIEW,
  DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND,
  DEFAULT_COLOR_ITEM,
  DEFAULT_REMOVE_ALL_FROM_VIEW,
  NUMERICAL_MINUS_ONE,
  NUMERICAL_ONE,
  NUMERICAL_ZERO, 
} from '@/constants/defaults';
import { FilterSelectType, ViewEditorColumnFilterFields } from '@/constants/enums';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  setViewEditorDraftAssignments,
  setViewEditorDraftAutoAddAssignments,
  setViewEditorDraftSortByAssignment,
  setViewEditorHideInactiveAssignments,
} from '@/store/slices/viewEditor.slice';
import { RootState } from '@/store/store';
import { AssignmentStructure } from '@/types/assignment.types';
import { AssignmentSortOrderDropDownOption, AssignmentSortOrderDropDownOptions } from '@/types/ui.types';
import { ViewColoredItem, ViewEditorDraftSortByAssignmentOptions } from '@/types/view.types';
import { updateColumnFilter } from '@/utils/updaters';

// Key to sort items on the tables
const ASSIGNMENT_SORT_KEY = 'id';

interface ViewEditorAssignmentsProps {
  assignmentsData: AssignmentStructure[];
  isAssignmentDataLoading: boolean;
}

type AssignmentColored = AssignmentStructure & ViewColoredItem;

enum ViewEditorAssignmentColumns {
  id = 'id',
  assignTypeId = 'assignTypeId',
  displayName = 'displayName',
  expired = 'expired',
}

function timeThenCustomOrder(a: AssignmentColored, b: AssignmentColored): number;
function timeThenCustomOrder(a: Row<AssignmentColored>, b: Row<AssignmentColored>): number;
function timeThenCustomOrder(
  itemA: AssignmentColored | Row<AssignmentColored>,
  itemB: AssignmentColored | Row<AssignmentColored>,
): number {
  // Type guard for Row<T>
  const a = 'original' in itemA ? itemA.original : itemA;
  const b = 'original' in itemB ? itemB.original : itemB;

  const aTime = a.startDate;
  const bTime = b.startDate;

  if (aTime === bTime) {
    // eslint-disable-next-line no-magic-numbers
    return 0;
  }

  // eslint-disable-next-line no-magic-numbers
  return aTime < bTime ? -1 : 1;
}

const timeThenName = (rowA: Row<AssignmentColored>, rowB: Row<AssignmentColored>, _columnId: string) => {
  const aTime = rowA.original.startDate;
  const aName = rowA.original.displayName;
  const bTime = rowB.original.startDate;
  const bName = rowB.original.displayName;

  if (aTime === bTime) {
    if (aName === bName)
      return NUMERICAL_ZERO;
    return aName < bName ? NUMERICAL_MINUS_ONE : NUMERICAL_ONE;
  }

  return aTime < bTime ? NUMERICAL_MINUS_ONE : NUMERICAL_ONE;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const doCustomSorting = (items: AssignmentColored[], sortOrder: AssignmentSortOrderDropDownOption) => {
  switch (sortOrder.value) {
    case ViewEditorDraftSortByAssignmentOptions.TIME_THEN_CUSTOM:
      return items.sort(timeThenCustomOrder);
    default:
      return items;
  }
};

interface ViewEditorAssignmentsState {
  assignmentSortValue: AssignmentSortOrderDropDownOption;
  assignmentToggleFilters: {
    autoAddAssignments: boolean | undefined;
  };
  availableItemsColumnFilters: ColumnFiltersState;
  selectedAssignmentTypes: number[];
}

enum ReducerActionTypes {
  SET_ASSIGNMENT_SORT_VALUE = 'SET_ASSIGNMENT_SORT_VALUE',
  SET_AVAILABLE_ITEMS_COLUMN_FILTERS = 'SET_AVAILABLE_ITEMS_COLUMN_FILTERS',
  SET_SELECTED_ASSIGNMENT_TYPES = 'SET_SELECTED_ASSIGNMENT_TYPES',
  TOGGLE_AUTO_ADD_ASSIGNMENTS = 'TOGGLE_AUTO_ADD_ASSIGNMENTS',
  TOGGLE_HIDE_INACTIVE_ASSIGNMENTS = 'TOGGLE_HIDE_INACTIVE_ASSIGNMENTS',
}

type ReducerAction = { type: ReducerActionTypes; payload: unknown };

const stateReducer = (draft: ViewEditorAssignmentsState, action: ReducerAction) => {
  switch (action.type) {
    case ReducerActionTypes.SET_ASSIGNMENT_SORT_VALUE: {
      draft.assignmentSortValue = action.payload as AssignmentSortOrderDropDownOption;
      return;
    }
    case ReducerActionTypes.TOGGLE_AUTO_ADD_ASSIGNMENTS: {
      draft.assignmentToggleFilters.autoAddAssignments = !draft.assignmentToggleFilters.autoAddAssignments;
      return;
    }
    case ReducerActionTypes.SET_SELECTED_ASSIGNMENT_TYPES: {
      draft.selectedAssignmentTypes = action.payload as number[];
      return;
    }
    case ReducerActionTypes.SET_AVAILABLE_ITEMS_COLUMN_FILTERS: {
      draft.availableItemsColumnFilters = action.payload as ColumnFiltersState;
      return;
    }
  }
};

const ViewEditorAssignments = (props: ViewEditorAssignmentsProps) => {
  const { assignmentsData, isAssignmentDataLoading } = props;

  const {
    uiState: { assignmentTypes, hideInactiveAssignments },
    viewDraft: view,
  } = useAppSelector((state: RootState) => state.viewEditor);

  const dispatch = useAppDispatch();

  const { filter } = view;

  const [state, localDispatch] = useImmerReducer(stateReducer, {
    assignmentSortValue:
      AssignmentSortOrderDropDownOptions.find(({ value }) => value === filter.sort_assignments_by) ??
      // eslint-disable-next-line no-magic-numbers
      AssignmentSortOrderDropDownOptions[0],
    assignmentToggleFilters: {
      autoAddAssignments: filter.auto_add_assignments,
    },
    availableItemsColumnFilters: [] as ColumnFiltersState,
    selectedAssignmentTypes: [],
  });

  const currentAssignmentStructureIds = React.useMemo(() => {
    return filter.on_assignments.map((assignment) => assignment.id);
  }, [filter.on_assignments]);

  const [availableItems, previewItems] = React.useMemo(() => {
    const availableItems = new Map<number, AssignmentColored>();
    const previewItems = new Map<number, AssignmentColored>();

    // If data are loading or there are no data, return empty maps
    if (isAssignmentDataLoading || !assignmentsData) {
      return [availableItems, previewItems];
    }

    // Hydrate available items
    for (const assignment of assignmentsData) {
      const { id } = assignment;

      if (currentAssignmentStructureIds.includes(id)) {
        continue;
      }

      const { color, colorText } = DEFAULT_COLOR_ITEM;
      const coloredAssignment = { ...assignment, color, colorText, id: assignment.id };

      availableItems.set(id, coloredAssignment);
    }

    // Hydrate preview items
    for (const filterAssignment of filter.on_assignments) {
      const { color, colorText, id } = filterAssignment;
      const assignment = assignmentsData.find((data) => data.id === id);

      if (!assignment) continue;

      const coloredAssignment = { ...assignment, color, colorText, id: assignment.id };

      previewItems.set(id as number, coloredAssignment);
    }

    return [availableItems, previewItems];
  }, [assignmentsData, currentAssignmentStructureIds, filter.on_assignments, isAssignmentDataLoading]);

  const isDraggable = React.useMemo(
    () =>
      filter.sort_assignments_by === ViewEditorDraftSortByAssignmentOptions.TIME_THEN_CUSTOM ||
      filter.sort_assignments_by === ViewEditorDraftSortByAssignmentOptions.CUSTOM,
    [filter.sort_assignments_by],
  );

  const assignmentTypeFilterFn = (row: Row<AssignmentColored>, _: string, filterValue: number[]) => {
    if (!filterValue.length) return true;

    const assignTypeId = row.getValue(ViewEditorAssignmentColumns.assignTypeId);

    return filterValue.includes(assignTypeId as number);
  };

  assignmentTypeFilterFn.autoRemove = (val: string) => !val;

  const columnHelper = createColumnHelper<AssignmentColored>();

  const availableItemsColumns = [
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.displayName, {
      cell: undefined,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorAssignmentColumns.displayName,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.typeId, {
      cell: undefined,
      enableHiding: true,
      filterFn: assignmentTypeFilterFn,
      id: ViewEditorAssignmentColumns.assignTypeId,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.expired, {
      cell: undefined,
      enableHiding: true,
      filterFn: 'equals',
      id: ViewEditorAssignmentColumns.expired,
    }),
    // eslint-disable-next-line react/prop-types
    columnHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorAssignmentColumns.id,
    }),
  ];

  const previewColumnsHelper = createColumnHelper<AssignmentColored>();

  const previewColumnFilterFn = React.useMemo(() => {
    const { value } = state.assignmentSortValue;

    switch (value as string) {
      case ViewEditorDraftSortByAssignmentOptions.NAME:
        return ALPHANUMERIC_TABLE_SORT;
      case ViewEditorDraftSortByAssignmentOptions.TIME_THEN_NAME:
        return timeThenName;
      default:
        return ALPHANUMERIC_TABLE_SORT;
    }
  }, [state.assignmentSortValue]);

  const previewColumns = [
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.displayName, {
      cell: undefined,
      enableSorting: true,
      header: () => <Box>Name</Box>,
      id: ViewEditorAssignmentColumns.displayName,
      sortingFn: previewColumnFilterFn,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.typeId, {
      cell: undefined,
      enableHiding: true,
      // filterFn: assignmentTypeFilterFn,
      id: ViewEditorAssignmentColumns.assignTypeId,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.expired, {
      cell: undefined,
      enableHiding: true,
      filterFn: 'equals',
      id: ViewEditorAssignmentColumns.expired,
    }),
    // eslint-disable-next-line react/prop-types
    previewColumnsHelper.accessor((row) => row.id, {
      cell: undefined,
      enableHiding: true,
      header: undefined,
      id: ViewEditorAssignmentColumns.id,
    }),
  ];

  const handleItemsChanged = React.useCallback(
    (items: AssignmentColored[]) => {
      const coloredItems = items.map((item) => {
        return {
          color: item.color,
          colorText: item.colorText,
          id: item.id,
        };
      });

      dispatch(setViewEditorDraftAssignments(coloredItems));
    },
    [dispatch],
  );

  const handleAutoAddAssignmentsToggle = () => {
    localDispatch({
      payload: null,
      type: ReducerActionTypes.TOGGLE_AUTO_ADD_ASSIGNMENTS,
    });
    dispatch(setViewEditorDraftAutoAddAssignments(!state.assignmentToggleFilters.autoAddAssignments));
  };

  const handleSelectAssignmentType = (values: unknown) => {
    const selectedAssignmentTypes = (values as FilterSelectValue[]).map((assignmentType) => assignmentType.value);

    localDispatch({
      payload: selectedAssignmentTypes,
      type: ReducerActionTypes.SET_SELECTED_ASSIGNMENT_TYPES,
    });

    const updatedAvailableItemsColumnFilters = updateColumnFilter(
      ViewEditorColumnFilterFields.ASSIGN_TYPE_ID,
      selectedAssignmentTypes,
      state.availableItemsColumnFilters,
    );

    localDispatch({
      payload: updatedAvailableItemsColumnFilters,
      type: ReducerActionTypes.SET_AVAILABLE_ITEMS_COLUMN_FILTERS,
    });
  };

  const handleInactiveAssignmentsToggle = React.useCallback(() => {
    dispatch(setViewEditorHideInactiveAssignments(!hideInactiveAssignments));
  }, [dispatch, hideInactiveAssignments]);

  const getColumnFilterIndexByName = React.useCallback(
    (name: ViewEditorAssignmentColumns) => {
      return (state.availableItemsColumnFilters as ColumnFiltersState).findIndex((filter) => filter.id === name);
    },
    [state.availableItemsColumnFilters],
  );

  React.useEffect(() => {
    const columnFilterIndex = getColumnFilterIndexByName(ViewEditorAssignmentColumns.expired);

    // Filter is enabled for table, but disabled in ui
    // OR filter is not enabled for table, but enabled in ui
    // ergo we need to update the state
    if (
      (columnFilterIndex !== DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND && !hideInactiveAssignments) ||
      (columnFilterIndex === DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND && hideInactiveAssignments)
    ) {
      const columnFiltersDraft = updateColumnFilter(
        ViewEditorAssignmentColumns.expired,
        hideInactiveAssignments,
        state.availableItemsColumnFilters,
        [ViewEditorAssignmentColumns.expired],
      );

      localDispatch({
        payload: columnFiltersDraft,
        type: ReducerActionTypes.SET_AVAILABLE_ITEMS_COLUMN_FILTERS,
      });
    }
  }, [getColumnFilterIndexByName, hideInactiveAssignments, localDispatch, state]);

  const getTopBar = () => {
    return (
      <HStack spacing="24px" alignItems="flex-start">
        <VStack alignItems="flex-start">
          <FormLabel>Filter Available Assignments by Assignment Types</FormLabel>
          <FilterSelect
            noGap
            hasSelectAll={true}
            inputName={FilterSelectType.FILTER_BY_ASSIGNMENT_TYPE}
            isMultiSelect={true}
            onChangeHandler={handleSelectAssignmentType}
            optionLabelKey={'name'}
            options={assignmentTypes}
            optionValueKey={'atypeId'}
            placeHolderText={'Assignment Types'}
            selectedValues={state.selectedAssignmentTypes}
            styles={{ container: (styles) => ({ ...styles, zIndex: 9999 }) }}
          />
        </VStack>

        <VStack alignItems="flex-start">
          <FormLabel>Sort Selected Assignments by</FormLabel>
          <Select
            options={AssignmentSortOrderDropDownOptions}
            onChange={(option) => {
              localDispatch({ payload: option, type: ReducerActionTypes.SET_ASSIGNMENT_SORT_VALUE });
              dispatch(
                setViewEditorDraftSortByAssignment(option?.value ?? ViewEditorDraftSortByAssignmentOptions.CUSTOM),
              );
            }}
            styles={{
              container: (styles) => ({
                ...styles,
                zIndex: 9999,
              }),
              control: (styles) => ({
                ...styles,
                maxWidth: '250px',
                minWidth: '250px',
              }),
            }}
            value={state.assignmentSortValue}
          />
        </VStack>

        <VStack alignItems="flex-end" height="100%" justifyContent="flex-end">
          <FormControl display="flex" alignItems="center">
            <HStack w={'240px'} justifyContent={'space-between'}>
              <FormLabel htmlFor="auto-add-assignments" mb="0">
                Auto-Add Assignments
              </FormLabel>
              <Switch
                id="auto-add-assignments"
                isChecked={state.assignmentToggleFilters.autoAddAssignments}
                onChange={handleAutoAddAssignmentsToggle}
              />
            </HStack>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <HStack w={'240px'} justifyContent={'space-between'}>
              <FormLabel htmlFor="hide-inactive-assignments-from-available" mb="0">
                Hide Inactive Assignments
              </FormLabel>
              <Switch
                id="hide-inactive-assignments-from-available"
                isChecked={hideInactiveAssignments}
                onChange={handleInactiveAssignmentsToggle}
              />
            </HStack>
          </FormControl>
        </VStack>
      </HStack>
    );
  };

  return (
    <VStack gap={5} align={'top'}>
      {getTopBar()}
      <AvailablePreviewList
        availableColumns={availableItemsColumns}
        availableColumnsVisibility={{
          [ViewEditorAssignmentColumns.id]: false,
          [ViewEditorAssignmentColumns.assignTypeId]: false,
          [ViewEditorAssignmentColumns.expired]: false,
        }}
        availableItems={availableItems}
        availableItemsColumnFilters={state.availableItemsColumnFilters}
        availableItemsPrimaryColumnId={ViewEditorAssignmentColumns.displayName}
        availableItemsTableLabel={'Available Assignments'}
        availableRowUniqueKey={ASSIGNMENT_SORT_KEY}
        deselectAllTextOverride={DEFAULT_REMOVE_ALL_FROM_VIEW}
        isOpen={true}
        isLoading={isAssignmentDataLoading}
        onItemsChanged={handleItemsChanged}
        overrideSorting={isDraggable}
        previewColumns={previewColumns}
        previewColumnsVisibility={{
          [ViewEditorAssignmentColumns.id]: false,
          [ViewEditorAssignmentColumns.assignTypeId]: false,
          [ViewEditorAssignmentColumns.expired]: false,
        }}
        previewItems={previewItems}
        previewItemsPrimaryColumnId={ViewEditorAssignmentColumns.displayName}
        previewItemsTableLabel={'Selected Assignments'}
        previewRowsAreDraggable={isDraggable}
        previewRowUniqueKey={ASSIGNMENT_SORT_KEY}
        primaryAvailableColumnIndex={0}
        primaryPreviewColumnIndex={0}
        selectAllTextOverride={DEFAULT_ADD_ALL_TO_VIEW}
        showColorPicker={true}
        showSideControls={true}
        tableColumnIds={ViewEditorAssignmentColumns}
      />
    </VStack>
  );
};

export default ViewEditorAssignments;
